<template>
    <modal ref="modalOperarios" :titulo="titulo" tamano="modal-lg" no-aceptar no-cancelar cerrar>
        <div class="row mx-0 mb-4 justify-center">
            <div class="col-9 pl-3 f-17 text-general f-600">
                {{ fecha }}
            </div>
            <div class="col-9 pl-3 d-middle">
                <div class="col-auto px-0 f-17 text-general f-600">
                    {{ agregarSeparadoresNumero(total) }} {{ params.tipo == 1 ? 'Devoluciones' : 'Eliminaciones' }} - {{ separadorNumero(dinero) }}
                </div>
                <div class="col-auto d-middle text-general f-600 f-17 ml-auto">
                    Ordenar por
                    <div class="br-12 d-middle ml-2 border-black">
                        <el-tooltip placement="bottom" content="Dinero" effect="light">
                            <div class="p-2 br-l-12 cr-pointer h" :class="filtro == 1 ? 'bg-general3 text-white' : 'text-general'" @click="filtro = 1">
                                <i class="icon-currency-usd-circle" />
                            </div>
                        </el-tooltip>
                        <el-tooltip placement="bottom" content="Cantidad" effect="light">
                            <div class="p-2 br-r-12 cr-pointer h" :class="filtro == 2 ? 'bg-general3 text-white' : 'text-general'" @click="filtro = 2">
                                <i class="icon-package-variant-closed" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div class="col-9">
                <div v-for="(user, u) in sortAdmin" :key="u" class="row mx-0">
                    <div class="col-auto px-1">
                        <img :src="user.foto" width="50" height="50" class="obj-cover br-12 border" />
                    </div>
                    <div class="col px-2">
                        <p class="text-general f-15">
                            {{ user.nombre }}
                        </p>
                        <div class="row mx-0 mt-1">
                            <div class="col-auto f-16 px-0">
                                {{ agregarSeparadoresNumero(user.cantidad) }} Productos
                            </div>
                            <div class="col-auto f-16">
                                {{ separadorNumero(user.dinero) }}
                            </div>
                        </div>
                        <div class="row mx-0" style="height:10px;">
                            <div class="bg-gris h-100 br-12" :style="`width:${agregarSeparadoresNumero(filtro == 1 ? user.porcentaje_max_dinero : user.porcentaje_max_cantidad)}%;`" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Estadisticas from '~/services/estadisticas_devoluciones_eliminaciones'

export default {
    props: {
        titulo: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            url_image: 'https://lamenteesmaravillosa.com/wp-content/uploads/2018/09/hombre-creido-pensando-que-sabe.jpg',
            buttons: [
                { texto: 'Cerrar', color: 'bg-light-f5 text-general2 px-3'}
            ],
            filtro: 1,
            total: 0,
            dinero: 0,
            admin: [],
            cargandoInforme: true,
            fecha: '',
            params: {

            },
        }
    },
    computed:{
        sortAdmin(){
            let admin = []
            admin = _.orderBy(this.admin, this.filtro == 1 ? 'dinero': 'cantidad','desc')

            return admin
        }
    },
    methods: {
        toggle(params){
            this.params = params
            this.fecha = `Durante : ${this.formatearFecha(params.fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(params.fecha_fin,'D MMM YYYY')}`

            this.verMasAdmin()
            this.$refs.modalOperarios.toggle()
        },
        async verMasAdmin(){
            try {
                this.cargandoInforme = false

                const {data} = await Estadisticas.verMasAdmin(this.params)
                this.admin = data.admin
                this.total = data.total
                this.dinero = data.dinero

            } catch (e){
                this.error_catch(e)
            } finally{
                this.cargandoInforme = true
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{ border-radius: 12px; }
.br-l-12{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.br-r-12{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.h:hover{
    transition: 0.2s;
    background-color: var(--text-general);
    color: #ffffff;
}
</style>
